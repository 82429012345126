@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  min-width: 500px;
}

.body {
  padding: 0 !important;
  height: 70vh;
}

.mapContainer {
  border-radius: "0 10px 10px 10px";
  overflow: hidden;
  height: 45%;
}

.listContainer {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
}

.expandedList {
  height: calc(100% - 20px);
}

.collapsedList {
  height: calc(55% + 40px);
}

.pickupPointsListContainer {
  height: calc(100% - 100px);
  overflow-x: auto;
}
